import React, { useContext, useState } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
} from '@material-ui/core';

import Context from '../../Context';

const FilterDailog = () => {
  const { state, setState } = useContext(Context);
  const { years, months, islamicMonths, dialog } = state;

  const [year, setYear] = useState(state.year);
  const [month, setMonth] = useState(state.month);
  const [islamicMonth, setIslamicMonth] = useState(state.islamicMonth);
  const [hasAudio, setHasAudio] = useState(state.hasAudio);

  return (
    <Dialog open={dialog === 'filter'}>
      <DialogTitle>
        Filter
      </DialogTitle>

      <DialogContent dividers style={{ minWidth: '200px' }}>
        <FormControl fullWidth style={{ marginBottom: '15px' }}>
          <InputLabel>Year</InputLabel>
          <Select
            native
            value={year}
            onChange={({ target: { value } }) => setYear(value)}
          >
            {years.map(({ label, value }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: '15px' }}>
          <InputLabel>Month</InputLabel>
          <Select
            native
            value={month}
            onChange={({ target: { value } }) => setMonth(value)}
          >
            {months.map(({ label, value }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: '15px' }}>
          <InputLabel>Islamic Month</InputLabel>
          <Select
            native
            value={islamicMonth}
            onChange={({ target: { value } }) => setIslamicMonth(value)}
          >
            {islamicMonths.map(({ label, value }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={(
            <Checkbox
              checked={hasAudio}
              color="primary"
              onChange={({ target: { checked } }) => {
                setHasAudio(checked);
              }}
            />
          )}
          label="Has audio recording"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setState({ year, month, islamicMonth, hasAudio, dialog: '' })}>
          Apply
        </Button>

        <Button onClick={() => setState({ dialog: '' })}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDailog;