import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from '@reach/router';

// -- mui --
import {
  AppBar,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Zoom,
  useScrollTrigger,
} from '@material-ui/core';

import {
  ArrowUpward,
  CloudDownload,
} from '@material-ui/icons';

// -- custom --
import Context from '../Context';
import FilterDialog from './components/FilterDialog';
import SideDrawer from './components/SideDrawer';
import SortDrawer from './components/SortDrawer';
import Toast from './components/Toast';
import Toolbar from './components/Toolbar';

const s3Url = 'https://khutbah-archive.s3-eu-west-1.amazonaws.com/content';

const KhutbahList = () => {
  const { language = 'en' } = useParams();
  const { state, setState } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedKhutbah, setSelectedKhutbah] = useState({ contentTypes: [] });

  const {
    showLinearProgress,
    showCircularProgress,
    searchString,
    year,
    month,
    islamicMonth,
    hasAudio,
    sortInstruction,
    loadMore,
    page,
    totalPages,
    khutbahs,
    months,
  } = state;

  useEffect(() => {
    const main = async () => {
      setState({
        showLinearProgress: true,
      });
      
      try {
        const { data } = await axios.get('https://beta-api.khutbaharchive.com', {
          params: {
            year,
            month,
            islamicMonth,
            hasAudio,
            sortInstruction,
            searchString,
          },
        });
          
        setState({
          showLinearProgress: false,
          loadMore: false,
          page: parseInt(data.page, 10),
          totalPages: parseInt(data.totalPages, 10),
          khutbahs: data.khutbahs,
        });
      } catch (err) {
        setState({
          showLinearProgress: false,
          loadMore: false,
          showToast: true,
          toastIcon: 'error',
          toastMessage: 'Gateway timed out',
        });
      }
    };
      
    main();
  }, [
    year,
    month,
    islamicMonth,
    hasAudio,
    sortInstruction,
    language,
    searchString,
    setState,
  ]);

  // if loadMore is true append nextPage to the list
  useEffect(() => {
    if (
      !loadMore
      || page >= totalPages
    ) {
      return;
    }

    const main = async () => {
      try {
        setState({
          showCircularProgress: true,
        });

        const { data } = await axios.get('https://beta-api.khutbaharchive.com', {
          params: {
            searchString,
            year,
            month,
            islamicMonth,
            hasAudio,
            sortInstruction,
            page: 1 + page,
          },
        });
          
        setState({
          showCircularProgress: false,
          loadMore: false,
          page: parseInt(data.page, 10),
          totalPages: parseInt(data.totalPages, 10),
          khutbahs: khutbahs.concat(data.khutbahs),
        });
      } catch (err) {
        setState({
          showCircularProgress: false,
          loadMore: false,
          showToast: true,
          toastIcon: 'error',
          toastMessage: 'Gateway timed out',
        });
      }
    };
      
    main();
  }, [loadMore]);

  // set loadMore to true once the scroll reaches the end of the list
  const listEl = useRef(null);
  useEffect(() => {
    const listener = () => {
      if ((window.innerHeight + document.documentElement.scrollTop) < listEl.current.offsetHeight) {
        return;
      }

      setState({ loadMore: true });
    };

    window.addEventListener('scroll', listener, { passive: true });

    return () => window.removeEventListener('scroll', listener);
  }, []);
    
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <div className="KhutbahList">
      <FilterDialog />
      <SortDrawer />
      <SideDrawer />
      <Toast />

      <AppBar
        id="back-to-top-anchor"
        color="inherit"
        position="static"
      >
        <Toolbar />
      </AppBar>

      {showLinearProgress && <LinearProgress />}

      <List ref={listEl} dense>
        {khutbahs.map((khutbah) => {
          const {
            deliverDate,
            title,
          } = khutbah;

          const [yyyy, mm, dd] = deliverDate.split('-');
          const monthIndex = parseInt(mm, 10);

          return (
            <React.Fragment key={deliverDate}>
              <ListItem selected={selectedKhutbah.deliverDate === deliverDate}>
                <ListItemText
                  primary={title}
                  secondary={`${dd} ${months[monthIndex].label.substr(0, 3)} ${yyyy}`}
                />
                
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={({ target }) => {
                      setAnchorEl(target);
                      setSelectedKhutbah(khutbah);
                    }}
                  >
                    <CloudDownload fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
  
              <Divider />
            </React.Fragment>
          );
        })}
      </List>

      {showCircularProgress && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '64px',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setSelectedKhutbah({ contentTypes: [] });
        }}
      >
        {selectedKhutbah.contentTypes
        .filter(contentType => contentType !== 'html')
        .map(contentType => (
          <MenuItem
            key={contentType}
            onClick={() => {
              window.open(`${s3Url}/${selectedKhutbah.deliverDate}/${language}.${contentType}`)
              setAnchorEl(null);
            }}
          >
            {contentType}
          </MenuItem>
        ))}
      </Menu>

      <Zoom in={trigger}>
        <Fab
          style={{
            position: 'fixed',
            bottom: '60px',
            right: '12px',
          }}
          color="primary"
          onClick={() => {
            const anchor = document.querySelector('#back-to-top-anchor');
            anchor.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }}
        >
          <ArrowUpward />
        </Fab>
      </Zoom>
    </div>
  );
}

export default KhutbahList;