import React, { useContext, useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';

import {
  Badge,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Toolbar
} from '@material-ui/core';
import {
  ArrowBack,
  Brightness4,
  Brightness7,
  Search,
  SwapVert,
  Translate,
  Tune,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

import Context from '../../Context';

let timeoutId;

const DefaultToolbar = () => {
  const { state, setState } = useContext(Context);
  const {
    darkMode,
    languages,
    showSearchToolbar,
  } = state;
  const { language = 'en' } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (showSearchToolbar) {
      setSearchString('');
    }
  }, [showSearchToolbar]);

  return (
    <Toolbar style={{ padding: '0px' }}>
      {!showSearchToolbar && (
        <div style={{ flexGrow: 1 }}>
          <IconButton onClick={() => setState({ drawer: 'side' })}>
            <MenuIcon />
          </IconButton>
        </div>
      )}

      {!showSearchToolbar && (
        <IconButton onClick={() => setState({ showSearchToolbar: true })}>
          <Search />
        </IconButton>
      )}

      {showSearchToolbar && (
        <IconButton
          onClick={() => setState({
            showSearchToolbar: false,
            searchString: '',
            sortInstruction: 'deliverDate-desc',
          })}
        >
          <ArrowBack />
        </IconButton>
      )}

      {showSearchToolbar && (
        <TextField
          value={searchString}
          onChange={({ target: { value } }) => {
            setSearchString(value);

            if (timeoutId) {
              clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
              setState({
                searchString: value,
                sortInstruction: '_score-desc',
              });
            }, 300);
          }}
          autoFocus
          fullWidth
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}

      <IconButton onClick={() => setState({ dialog: 'filter' })}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={
            !state.year
            && !state.month
            && !state.islamicMonth
            && !state.hasAudio
          }
        >
          <Tune />
        </Badge>
      </IconButton>

      <IconButton onClick={() => setState({ drawer: 'sort' })}>
        <SwapVert />
      </IconButton>

      <IconButton onClick={() => setState({ darkMode: !darkMode })}>
        {darkMode ? <Brightness7 /> : <Brightness4 />}
      </IconButton>

      {!showSearchToolbar && (
        <div>
          <IconButton onClick={(({ target }) => setAnchorEl(target))}>
            <Translate />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {languages.map(({ label, value }) => (
              <MenuItem
                key={value}
                selected={value === language}
                onClick={() => {
                  navigate(`/${value}`, { replace: true })
                  setAnchorEl(null);
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </Toolbar>
  );
};

export default DefaultToolbar;