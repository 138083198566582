// -- react, redux --
import React, {
  useMemo,
  useReducer,
} from 'react';
import { Router } from "@reach/router"

// -- mui --
import {
  blue,
  pink,
} from '@material-ui/core/colors';
import {
  createMuiTheme,
  darken,
} from '@material-ui/core/styles';
import {
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';

// -- custom --
import Context from './Context';
import KhutbahList from './khutbah-list/KhutbahList';
import Khutbah from './khutbah/Khutbah';

const years = [
  2020,
  2019,
  2018,
  2017,
  2016,
].map(value => ({ label: `${value}`, value }));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((label, i) => ({ label, value: i + 1 }));

const islamicMonths = [
  'Muḥarram',
  'Ṣafar',
  'Rabīʿ al-Awwal',
  'Rabīʿ al-Thānī ',
  'Jumādá al-Ūlá',
  'Jumādá al-Ākhirah',
  'Rajab',
  'Sha‘bān',
  'Ramaḍān',
  'Shawwāl',
  'Dhū al-Qa‘dah',
  'Dhū al-Ḥijjah',
].map((label, i) => ({ label, value: i }));

const empty = [{ label: '', value: '' }];

const initialState = {
  darkMode: true,

  year: '',
  years: empty.concat(years),

  month: '',
  months: empty.concat(months),

  islamicMonth: '',
  islamicMonths: empty.concat(islamicMonths),

  hasAudio: false,

  sortInstruction: 'deliverDate-desc',
  sortOptions: [{
    label: 'Relevance',
    value: '_score-desc',
  }, {
    label: 'Latest first',
    value: 'deliverDate-desc',
  }, {
    label: 'Oldest first',
    value: 'deliverDate-asc',
  }],

  languages: [{
    label: 'English',
    value: 'en',
  }, {
    label: 'Arabic',
    value: 'ar',
  }, {
    label: 'Urdu',
    value: 'ur',
  }, {
    label: 'Spanish',
    value: 'es',
  }],

  searchString: '',

  page: 0,

  showToast: false,
  toastIcon: 'error',
  toastMessage: 'test',

  khutbahs: [],
};
const reducer = (state, update) => ({
  ...state,
  ...update,
});

const App = () => {
  const [state, setState] = useReducer(reducer, initialState);

  const { darkMode } = state;

  const theme = useMemo(
    () => createMuiTheme({
      palette: {
        type: darkMode ? 'dark' : 'light',
        primary: {
          main: darkMode ? blue[200] : blue[700],
        },
        secondary: {
          main: darkMode ? pink[200] : darken(pink.A400, 0.1),
        },
      },
    }),
    [darkMode],
  );

  return (
    <Context.Provider value={{ state, setState }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <KhutbahList path="/" />
          <KhutbahList path="/:language" />
          <Khutbah path="/:language/:date" />
        </Router>
      </ThemeProvider>
    </Context.Provider>
  );
};

export default App;
