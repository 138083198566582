// -- react --
import React, { useContext } from 'react';

// -- mui --
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';

// -- custom --
import Context from '../../Context';

const SideDrawer = () => {
  const { state, setState } = useContext(Context);
  const { drawer } = state;

  return (
    <Drawer
      anchor="left"
      open={drawer === 'side'}
      onClose={() => setState({ drawer: '' })}
    >
      <List style={{ width: '70vw' }}>
        <ListItem>
          <ListItemText>
            Khutbah Archive
          </ListItemText>
        </ListItem>

        <Divider />
      </List>
    </Drawer>
  );
}

export default SideDrawer;