// -- react --
import React, { useContext } from 'react';

// -- mui --
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Context from '../../Context';

const Toast = () => {
  const { state, setState } = useContext(Context);
  const {
    showToast,
    toastIcon,
    toastMessage,
  } = state;

  if (!toastIcon) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={showToast}
      autoHideDuration={1500}
      onClose={() => {
        setState({
          showToast: false,
        });
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={toastIcon}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};


export default Toast;
