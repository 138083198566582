import React, { useContext } from 'react';

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import {
  Check,
} from '@material-ui/icons';

import Context from '../../Context';

const SortDrawer = () => {
  const { state, setState } = useContext(Context);
  const { drawer, sortOptions, sortInstruction } = state;

  return (
    <Drawer
      anchor="top"
      open={drawer === 'sort'}
      onClose={() => setState({ drawer: '' })}
    >
      <Typography
        style={{ margin: '16px 0px 0px 16px' }}
        variant="body2"
      >
        Sort by
      </Typography>

      <List>
        {sortOptions.map(({ label, value }) => (
          <ListItem
            key={value}
            onClick={() => setState({ drawer: '', sortInstruction: value })}
          >
            <ListItemText>{label}</ListItemText>

            {sortInstruction === value && (
              <ListItemSecondaryAction>
                <Check color="primary" />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default SortDrawer;